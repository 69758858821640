import React from 'react';
import { compose, withProps } from 'recompose';
import { validate as validateEmail } from 'isemail';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash-es';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import SubmitButton from '@dpdgroupuk/fmx-ui/components/SubmitButton';
import type { AuthorizedParcel } from '@dpdgroupuk/fmx-ui/types';
import { withTrack, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { PARCEL_ACTION_CODE } from '@dpdgroupuk/redback-enums';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';

import withFetch from '../../../../../HOCs/withFetch';

import RadioSquare from './RadioSquare';

import { makePaymentParams } from '../../../../../utils/payment';
import { onProcessPaymentResult } from '../../../../../utils/threeDSecure';
import { catchRequestError } from '../../../../../utils/errorHandling';
import { fetchDutyPaymentToken } from '../../../actions';
import { DTY_PAYMENT, trackable } from '../../../../../constants/analytics';
import { calculateVat } from '../model';
import { getInfoForThreeDSecure } from '../../../../../models/parcel';
import { openInNewTab } from '../../../../../utils/location';
import {
  CONTACT_US,
  DUTY_PAYMENT_TERMS_CONDITIONS_HINT,
  EMAIL_FOR_VAT_INVOICE,
  FUTURE_IMPORTS_MESSAGE,
  PARCEL_PAID_MESSAGE,
  OK,
} from '../../../../../constants/message';
import { withRemoteConfig } from '../../../../../components/RemoteConfig/ConfigContext';
import useBraintree from '../../../../../components/BraintreeWebDropIn/hooks/useBraintree';
import { fetchDeliveryActions } from '../../../../DeliveryOptions/actions';
import { goToTracking } from '../../../../historyActions';
import { BRAINTREE_PAYMENT_CANCEL } from '../../../../../constants/error';
import {
  CHALLENGE_REQUIRED_STATUS,
  getModalErrorMessage,
} from '../../../../../constants/threeDSecure';

const useStyles = makeStyles(
  ({ spacing, breakpoints, palette, typography }) => ({
    container: {
      textAlign: 'left',
      marginBottom: typography.pxToRem(8),
    },
    title: {
      marginBottom: spacing(2),

      '& .braintree-heading': {
        width: '100%',
        textAlign: 'center',
        position: 'inherit',
        [breakpoints.down('xs')]: {
          fontSize: typography.pxToRem(14),
        },
      },
      '& .braintree-sheet__label, .braintree-heading': {
        color: '#000',
      },
      '& .braintree-option__label': {
        [breakpoints.down('xs')]: {
          fontSize: typography.pxToRem(14),
          marginLeft: 0,
        },
      },
      '& .braintree-placeholder': {
        display: 'none',
      },
      '& .braintree-sheet__header': {
        display: 'none',
      },
      '& [data-braintree-id="number-field-group"] > label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [breakpoints.down('xs')]: {
          display: 'block',
        },
        '& .braintree-form__field': {
          width: '80%',
          [breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },
      '& .braintree-sheet__content--form .braintree-form__flexible-fields [data-braintree-id="expiration-date-field-group"]': {
        marginBottom: 0,
        [breakpoints.down('xs')]: {
          flexBasis: '70%',
          flexGrow: 0,
          width: '70%',
          paddingRight: typography.pxToRem(5),
        },
      },
      '& .braintree-sheet__content--form .braintree-form__flexible-fields [data-braintree-id="cvv-field-group"]': {
        marginBottom: 0,
        [breakpoints.down('xs')]: {
          flexBasis: '30%',
          flexGrow: 0,
          width: '30%',
        },
      },
      '& [data-braintree-id="save-card-field-group"]': {
        marginBottom: 0,
      },
      '& [data-braintree-id="toggle"]': {
        paddingTop: 0,
        paddingBottom: typography.pxToRem(10),
      },
      '& [data-braintree-id="cvv-label-descriptor"]': {
        display: 'none',
      },
    },
    vatInvoiceItem: {
      [breakpoints.down('xs')]: {
        display: 'flex',
        flexWrap: 'nowrap',
      },
    },
    text: {
      display: 'inline-block',
      [breakpoints.down('xs')]: {
        fontSize: typography.pxToRem(15),
        paddingRight: typography.pxToRem(10),
      },
    },
    subtitle: {
      marginTop: typography.pxToRem(16),
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(10),
        marginBottom: typography.pxToRem(5),
      },
    },
    radioIcon: {
      cursor: 'pointer',
      marginRight: typography.pxToRem(7),
      '& .checkMark': {
        // fill: palette.primary.main,
      },
      [breakpoints.down('xs')]: {
        alignSelf: 'flex-start',
        marginRight: 0,
        paddingLeft: typography.pxToRem(10),
      },
    },
    contactUs: {
      marginTop: typography.pxToRem(8),
      marginBottom: typography.pxToRem(20),
      '& a': {
        fontWeight: 500,
      },
      [breakpoints.down('xs')]: {
        fontSize: typography.pxToRem(15),
        marginTop: typography.pxToRem(10),
        paddingLeft: typography.pxToRem(10),
        paddingRight: typography.pxToRem(10),
      },
    },
    terms: {
      marginTop: typography.pxToRem(8),
      marginBottom: typography.pxToRem(8),
      [breakpoints.down('xs')]: {
        marginTop: typography.pxToRem(8),
      },
    },
    textFieldWrapper: {
      flex: 1,
    },
    termsWrapper: {
      flex: 1,
      paddingLeft: '0 !important',
      paddingTop: '5px !important',
    },
    vatText: {
      marginLeft: typography.pxToRem(8),
    },
    link: {
      color: '#1D6FD0',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  })
);

function VatDetails({
  selected,
  onClick,
  value,
  onChange,
  error,
}: {
  selected: boolean,
  onClick: Function,
  value: string,
  onChange: Function,
  error: boolean,
}) {
  const styles = useStyles();

  return (
    <Grid direction="row" container spacing={2}>
      <Grid item className={styles.vatInvoiceItem}>
        <RadioSquare
          selected={selected}
          className={styles.radioIcon}
          onClick={onClick}
        />
        <Typography
          variant="body1"
          className={clsx(styles.text, styles.vatText)}
        >
          {EMAIL_FOR_VAT_INVOICE}
        </Typography>
      </Grid>
      <Grid item className={styles.textFieldWrapper}>
        <TextField
          fullWidth
          value={value}
          variant="outlined"
          size="small"
          onChange={onChange}
          error={error}
        />
      </Grid>
    </Grid>
  );
}

function TermsAndConditions({
  selected,
  onClick,
  isCustomsDeclarationTypeE,
}: {
  selected: boolean,
  onClick: Function,
  isCustomsDeclarationTypeE: boolean,
}) {
  const styles = useStyles();

  return (
    <Grid direction="row" container spacing={2} className={styles.terms}>
      <Grid item>
        <RadioSquare
          selected={selected}
          className={styles.radioIcon}
          onClick={onClick}
        />
      </Grid>
      <Grid item className={styles.termsWrapper}>
        <Typography variant="body1" className={styles.text}>
          By ticking this box, I appoint DPDgroup UK Ltd. EORI:{' '}
          {isCustomsDeclarationTypeE ? 'XI754532232000' : 'GB754532232000'} to
          carry out customs formalities for in relation to the goods and to act
          on my behalf in the capacity of Customs Agent in accordance with
          section 21 of the Taxation (Cross Border Trade) Act 2018 and in
          accordance DPDgroup UK Ltd{' '}
          <a
            className={styles.link}
            href="https://www.dpd.co.uk/ts-and-cs.jsp"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
          . I understand that I will be responsible for payment of all duty in
          relation to the goods. DPDgroup UK Ltd will rely on the information
          provided by the sender of the goods in relation to the description of
          the goods and their value, but you must tell us immediately if there
          is any error in this information.
        </Typography>
        <Typography
          variant="body1"
          className={clsx(styles.text, styles.subtitle)}
        >
          {DUTY_PAYMENT_TERMS_CONDITIONS_HINT}
        </Typography>
      </Grid>
    </Grid>
  );
}

function DutyPaymentCapture({
  fetchedData: [tokenResult, defaultExport],
  onSubmit,
  parcel,
  onOpenTerms,
  onContactUs,
  termsLink,
  contactUsLink,
  modal,
  history,
  getActions,
  isCustomsDeclarationTypeE,
}: {
  fetchedData: Array,
  onSubmit: Function,
  parcel: AuthorizedParcel,
  onOpenTerms: Function,
  onContactUs: Function,
  termsLink: string,
  contactUsLink: string,
  modal: Object,
  history: History,
  getActions: Function,
  isCustomsDeclarationTypeE: boolean,
}) {
  const styles = useStyles();
  const { default: DropIn } = defaultExport;

  const { duty = {}, customsReceiverContactEmail } = parcel;

  // Component State
  const [vatInvoice, setVatInvoice] = React.useState(
    !!customsReceiverContactEmail
  );
  const [termsAccepted, setTermsAccepted] = React.useState(false);

  const { total } = calculateVat(duty);

  const [vatEmail, setVatEmail] = React.useState(customsReceiverContactEmail);
  const [state, api] = useBraintree(
    makePaymentParams(tokenResult.token, total),
    React.useCallback(error => catchRequestError({ error, modal, history }), [
      history,
      modal,
    ]),
    modal
  );

  // Callbacks
  const onTermsAcceptedClick = React.useCallback(() => {
    setTermsAccepted(value => !value);
  }, []);

  const onVatInvoiceCLick = React.useCallback(() => {
    setVatInvoice(value => !value);
  }, []);

  const onEmailChange = React.useCallback(e => {
    setVatEmail(e.target.value);
  }, []);

  const submitPayment = React.useCallback(async () => {
    const actions = await getActions(parcel.parcelCode);

    if (
      !actions ||
      !get(actions, 'availableActions', []).includes(PARCEL_ACTION_CODE.DTY)
    ) {
      modal.showModal({
        title: PARCEL_PAID_MESSAGE,
        buttonText: OK,
        description: null,
      });
      goToTracking({ history, parcel });
    }

    const paymentResult: any = await api.requestPaymentMethod({
      threeDSecure: getInfoForThreeDSecure(total, parcel),
    });

    return onProcessPaymentResult(
      paymentResult,
      () =>
        onSubmit({
          paymentNonce: paymentResult.nonce,
          paymentReference: paymentResult.type,
          total,
          contactEmail: vatInvoice ? vatEmail : null,
        }),
      ({ braintreeStatus }) => {
        // If status equals cancel status
        if ((braintreeStatus || '') === CHALLENGE_REQUIRED_STATUS) {
          modal.showModal({
            title: BRAINTREE_PAYMENT_CANCEL,
            buttonText: OK,
            description: null,
          });
        } else {
          modal.showModal({
            title: getModalErrorMessage(braintreeStatus),
            buttonText: OK,
            description: null,
          });
        }
      }
    );
  }, [
    onSubmit,
    total,
    vatEmail,
    vatInvoice,
    history,
    modal,
    parcel,
    getActions,
    api,
  ]);

  const isVatEmailInvalid =
    !vatEmail || (vatEmail && !validateEmail(vatEmail, { minDomainAtoms: 2 }));

  return (
    <>
      <Grid className={styles.title}>
        <DropIn ref={state.dropInRef} />
      </Grid>
      <div className={styles.container}>
        <VatDetails
          selected={vatInvoice}
          onClick={onVatInvoiceCLick}
          error={vatInvoice ? isVatEmailInvalid : null}
          value={vatEmail}
          onChange={onEmailChange}
        />
        <TermsAndConditions
          onClick={onTermsAcceptedClick}
          selected={termsAccepted}
          isCustomsDeclarationTypeE={isCustomsDeclarationTypeE}
          onOpenTerms={onOpenTerms}
          termsLink={termsLink}
        />
        {duty.customsReceiverVat ? (
          <Typography variant="body1" className={styles.contactUs}>
            {`${FUTURE_IMPORTS_MESSAGE} `}
            <Link
              href={contactUsLink}
              target="_blank"
              underline="always"
              color="inherit"
              onClick={onContactUs}
            >
              {CONTACT_US}
            </Link>
          </Typography>
        ) : null}
      </div>
      <SubmitButton
        onSubmit={submitPayment}
        isSubmitDisabled={
          !state.paymentMethodRequestable ||
          !termsAccepted ||
          (vatInvoice ? isVatEmailInvalid : false)
        }
      />
    </>
  );
}

export default compose(
  withRouter,
  connect(
    null,
    (dispatch, { parcel }) => ({
      getActions: () => dispatch(fetchDeliveryActions({ parcel })),
    })
  ),
  withFetch(
    ({ parcel }) => dispatch => {
      return Promise.all([
        dispatch(fetchDutyPaymentToken(parcel.parcelCode)),
        import('../../../../../components/BraintreeWebDropIn'),
      ]);
    },
    { deliveryOptions: true }
  ),
  withRemoteConfig(),
  withModal,
  withProps(
    ({
      submitWizard,
      remoteConfig: { dutyPaymentContactUsLink, dutyPaymentTermsLink },
    }) => ({
      onSubmit: data => {
        return submitWizard({
          stepData: data,
        });
      },
      onContactUs: e => {
        e.preventDefault();
        openInNewTab(dutyPaymentContactUsLink);
      },
      onOpenTerms: e => {
        e.preventDefault();
        openInNewTab(dutyPaymentTermsLink);
      },
      termsLink: dutyPaymentTermsLink,
      contactUsLink: dutyPaymentContactUsLink,
    })
  ),
  withTrack(trackable(DTY_PAYMENT)),
  withTrackProps({
    onSubmit: DTY_PAYMENT.ON_SUBMIT,
    onContactUs: DTY_PAYMENT.CONTACT_US,
    onOpenTerms: DTY_PAYMENT.TERMS_AND_CONDITIONS,
  })
)(DutyPaymentCapture);
